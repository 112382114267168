import * as Sentry from '@sentry/browser'
import getConfig from 'next/config'

const { publicRuntimeConfig } = getConfig()
const { SENTRY_DSN, APP_ENV } = publicRuntimeConfig;

if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  Sentry.init({
    dsn: SENTRY_DSN,
    environment: APP_ENV,
    release: process.env.BUILD_ID,
    maxBreadcrumbs: 50,
    attachStacktrace: true,
    integrations: [new Sentry.Integrations.Breadcrumbs({
      console: false,
      dom: true,
      xhr: true,
      fetch: true,
      history: true
    })]
  })
}
export default { Sentry };
