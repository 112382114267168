import { MAX_PRICE, MIN_PRICE, orderTradeDefault } from "./constants";
import CusSentry from '@src/common/sentry';
import getConfig from 'next/config';

const { publicRuntimeConfig } = getConfig();
const { OPENING_ACCOUNT_URL = '' }: { OPENING_ACCOUNT_URL: string } = publicRuntimeConfig;
const { OPENING_ACCOUNT_URL_APP = '' }: { OPENING_ACCOUNT_URL_APP: string } = publicRuntimeConfig;

export const helpContent: {
  [key: string]: {
    content: string[],
    linkButton?: {
      text: string,
      getLink?: () => string
    }
  }
} = {
  balance: {
    content: ['現在の仮想資金額を表示しています。', 'お取引に慣れたら、口座開設をして実際のお金で取引してみましょう。'],
    linkButton: {
      text: '口座開設へ',
      getLink: () => getOpenAccountUrl()
    }
  },
  history: {
    content: ['確定した損益額を表示しています。', '体験画面を終了した場合は、仮想資金と成績はリセットされます。'],
  },
  flag: {
    content: ['FX体験は米ドル／円のお取引限定となります。', '他の通貨ペアのお取引は、FX口座を開設後にFXアプリからご選択ください。'],
    linkButton: {
      text: '口座開設へ',
      getLink: () => getOpenAccountUrl()
    }
  },
  amount: {
    content: [`FX体験は${formatCurrency(orderTradeDefault)}ドルのお取引限定となります。`, '注文数量のご変更は、FX口座を開設後に実際のお取引画面からご選択ください。'],
    linkButton: {
      text: '口座開設へ',
      getLink: () => getOpenAccountUrl()
    }
  },
  profit: {
    content: ['現在の未確定分の損益額を表示しています。', '「損益を確定する」をタップすると確定します。', '損益はお取引が完了した後に確定するため、表示されている未確定の損益額と実際の損益額は異なる場合があります。'],
  }
}

export const handleDeposit = (cash: number) => {
  let notional: number;
  if (cash < 50000) {
    cash = 50000;
  } else if (cash > 10000000000000000) {
    cash = 10000000000000000
  }
  notional = cash * 25
  return notional
}

export const resultTrade = (last_price: number, start_price: number, notional: number, is_buy: boolean) => {
  let result: number;
  if (is_buy) { // incase => buy
    result = (notional / start_price) * (last_price - start_price)
  } else { // reduce => sell
    result = (notional / start_price) * (start_price - last_price)
  }
  return Math.round(result)
}

export const isForexDayoff = () => {
  const current = new Date();
  const currentMMDD = `${current.getMonth()}-${current.getDate()}`;
  const day = current.getDay();
  const hours = current.getHours();

  const isWeekend = ((day === 6) || (day === 0)) && hours >= 4;
  const isHoliday = ['12-25', '1-1'].includes(currentMMDD) && hours >= 4;

  return isWeekend || isHoliday;
}

export function formatCurrency(n: number, separate = ',') {
  const s = Number(n.toFixed(0)).toString();
  const regex = /\B(?=(\d{3})+(?!\d))/g;
  return s.replace(regex, separate);
}

export const generateNewPrice = (oldPrice: number) => {
  const ARR_RATE = [0.05, 0.1]
  const volatility = ARR_RATE[Math.floor(Math.random() * ARR_RATE.length)]
  const rnd = Math.random();
  let changePercent = 2 * volatility * rnd;
  if (changePercent > volatility) {
    changePercent -= (2 * volatility);
  }
  let changeAmount = oldPrice * changePercent / 100;
  let newPrice = oldPrice + changeAmount;
  // Add a ceiling and floor.
  if (newPrice < MIN_PRICE) {
    newPrice += Math.abs(changeAmount) * 2;
  } else if (newPrice > MAX_PRICE) {
    newPrice -= Math.abs(changeAmount) * 2;
  }
  newPrice = newPrice || MIN_PRICE;
  return parseFloat(newPrice.toFixed(3));
}

export const checkSymbol = (profit: number) => {
  const symbol = profit > 0 ? '+' : profit === 0 ? '±' : ''
  const color = profit > 0 ? '#369BD1' : profit === 0 ? '#4D4D4D' : '#E04350'
  return { symbol, color }
}

export const handleError = (error: { [key: string]: string }) => {
  const { Sentry } = CusSentry;
  Sentry.withScope(scope => {
    scope.setExtra("sagaStack", error);
    Sentry.captureException(error);
  });
}

export const getOpenAccountUrl = () => {
  let openAccountUrl = '/';
  if (typeof window !== 'undefined') {
    openAccountUrl = OPENING_ACCOUNT_URL
    let queryStr = window.location.search;

    openAccountUrl.includes('?') && (queryStr = queryStr.replace('?', '&'));
    queryStr = queryStr && queryStr.indexOf('CHANNEL=S000') >= 0 ? `${queryStr}&externalBrowser=1` : queryStr;
    openAccountUrl = `${openAccountUrl}${queryStr}`;
  }

  return openAccountUrl;
}

export const getOpenAccountUrlApp = () => {
  let openAccountUrlApp = '/';
  if (typeof window !== 'undefined') {
    openAccountUrlApp = OPENING_ACCOUNT_URL_APP
    let queryStr = window.location.search;

    openAccountUrlApp.includes('?') && (queryStr = queryStr.replace('?', '&'));
    queryStr = queryStr && queryStr.indexOf('CHANNEL=S000') >= 0 ? `${queryStr}&externalBrowser=1` : queryStr;
    openAccountUrlApp = `${openAccountUrlApp}${queryStr}`;
  }

  return openAccountUrlApp;
}

export class MobileDetectDevice {
  static options = [];
  static header = typeof window !== 'undefined' ? [window.navigator.platform, window.navigator.userAgent, window.navigator.appVersion, window.navigator.vendor] : [];
  static dataos = [
    { name: 'Windows Phone', value: 'Windows Phone', version: 'OS' },
    { name: 'Windows', value: 'Win', version: 'NT' },
    { name: 'iPhone', value: 'iPhone', version: 'OS' },
    { name: 'iPad', value: 'iPad', version: 'OS' },
    { name: 'Kindle', value: 'Silk', version: 'Silk' },
    { name: 'Android', value: 'Android', version: 'Android' },
    { name: 'PlayBook', value: 'PlayBook', version: 'OS' },
    { name: 'BlackBerry', value: 'BlackBerry', version: '/' },
    { name: 'Macintosh', value: 'Mac', version: 'OS X' },
    { name: 'Linux', value: 'Linux', version: 'rv' },
    { name: 'Palm', value: 'Palm', version: 'PalmOS' }
  ];

  static databrowser: [
    { name: 'Chrome', value: 'Chrome', version: 'Chrome' },
    { name: 'Firefox', value: 'Firefox', version: 'Firefox' },
    { name: 'Safari', value: 'Safari', version: 'Version' },
    { name: 'Internet Explorer', value: 'MSIE', version: 'MSIE' },
    { name: 'Opera', value: 'Opera', version: 'Opera' },
    { name: 'BlackBerry', value: 'CLDC', version: 'CLDC' },
    { name: 'Mozilla', value: 'Mozilla', version: 'Mozilla' }
  ];

  public init = () => {
    var agent = MobileDetectDevice.header.join(' ');
    var os = this.matchItem(agent, MobileDetectDevice.dataos);
    var browser = this.matchItem(agent, MobileDetectDevice.databrowser);

    return { os: os, browser: browser };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private matchItem = (string: any, data: any = []) => {
    var i = 0;
    var j = 0;
    var regex;
    var regexv;
    var match;
    var matches;
    var version;

    for (i = 0; i < data.length; i += 1) {
      regex = new RegExp(data[i].value, 'i');
      match = regex.test(string);
      if (match) {
        regexv = new RegExp(data[i].version + '[- /:;]([\\d._]+)', 'i');
        matches = string.match(regexv);
        version = '';
        if (matches) { if (matches[1]) { matches = matches[1]; } }
        if (matches) {
          matches = matches.split(/[._]+/);
          for (j = 0; j < matches.length; j += 1) {
            if (j === 0) {
              version += matches[j] + '.';
            } else {
              version += matches[j];
            }
          }
        } else {
          version = '0';
        }
        return {
          name: data[i].name,
          version: parseFloat(version)
        };
      }
    }
    return { name: 'unknown', version: 0 };
  }
}
export const unsupportedVersion = () => {
  const detectDevice = new MobileDetectDevice().init();
  if (detectDevice.os.name === 'Android') {
    return detectDevice.os.version < 6
  }

  if (detectDevice.os.name === 'iPhone') {
    return detectDevice.os.version < 10
  }
  return false;

}


export const isClient = () => typeof window !== 'undefined';
