import axios, { AxiosRequestConfig } from 'axios';
import getConfig from 'next/config'
import sha256 from 'crypto-js/sha256';

const { publicRuntimeConfig } = getConfig()
const { API_KEY = '' }: { API_KEY: string } = publicRuntimeConfig;

const baseHeaders = (config: AxiosRequestConfig) => ({
  'Content-Type': 'application/json',
  ...config.headers
});

// Config App Axios
const appAxios = axios.create();
appAxios.defaults.baseURL = publicRuntimeConfig.REACT_API_HOST;
appAxios.interceptors.request.use(function (config: AxiosRequestConfig) {
  const now = Date.now().toString();
  const payload = `${API_KEY}-${now}`;
  const hash = sha256(payload);
  return {
    ...config,
    headers: {
      ...baseHeaders(config),
      token: hash
    },
    params: {
      timestamp: now
    }
  }
});

export { appAxios };
export default appAxios;