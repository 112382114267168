export const HELP_CODE = {
  BALANCE: "balance",
  HISTORY: "history",
  FLAG: "flag",
  AMOUNT: "amount",
  PROFIT: "profit",
  TURN_OFF: "",
};

export const MAX_PRICE = 105.400;
export const MIN_PRICE = 105.000;
export const orderTradeDefault = 50000;
export const blanceVolumeDefault = 500000;

export const dataChart = [
  {name: "1", data: 105.197},
  {name: "2", data: 105.179},
  {name: "3", data: 105.190},
  {name: "4", data: 105.189},
  {name: "5", data: 105.174},
  {name: "6", data: 105.132},
  {name: "7", data: 105.169},
  {name: "8", data: 105.198},
  {name: "9", data: 105.114},
  {name: "10", data: 105.122},
  {name: "11", data: 105.111},
  {name: "12", data: 105.179},
  {name: "13", data: 105.123},
  {name: "14", data: 105.167},
  {name: "15", data: 105.128},
  {name: "16", data: 105.121},
  {name: "17", data: 105.124},
  {name: "18", data: 105.163},
  {name: "19", data: 105.170},
  {name: "20", data: 105.167},
  {name: "21", data: 105.161},
  {name: "22", data: 105.136},
  {name: "23", data: 105.146},
  {name: "24", data: 105.103},
  {name: "25", data: 105.186},
  {name: "26", data: 105.164},
  {name: "27", data: 105.182},
  {name: "28", data: 105.184},
  {name: "29", data: 105.116},
  {name: "30", data: 105.165},
  {name: "31", data: 105.131},
  {name: "32", data: 105.177},
  {name: "33", data: 105.132},
  {name: "34", data: 105.130},
  {name: "35", data: 105.181},
  {name: "36", data: 105.181},
  {name: "37", data: 105.100},
  {name: "38", data: 105.115},
  {name: "39", data: 105.189},
  {name: "40", data: 105.127},
  {name: "41", data: 105.165},
  {name: "42", data: 105.148},
  {name: "43", data: 105.150},
  {name: "44", data: 105.166},
  {name: "45", data: 105.167},
  {name: "46", data: 105.152},
  {name: "47", data: 105.150},
  {name: "48", data: 105.167},
  {name: "49", data: 105.145},
  {name: "50", data: 105.198},
  {name: "51", data: 105.119},
  {name: "52", data: 105.104},
  {name: "53", data: 105.157},
  {name: "54", data: 105.161},
  {name: "55", data: 105.123},
  {name: "56", data: 105.158},
  {name: "57", data: 105.179},
  {name: "58", data: 105.111},
  {name: "59", data: 105.138},
  {name: "60", data: 105.174},
  {name: "61", data: 105.127},
  {name: "62", data: 105.198},
  {name: "63", data: 105.119},
  {name: "64", data: 105.104},
  {name: "65", data: 105.157},
  {name: "66", data: 105.161},
  {name: "67", data: 105.123},
  {name: "68", data: 105.158},
  {name: "69", data: 105.179},
  {name: "70", data: 105.111},
  {name: "71", data: 105.138},
  {name: "72", data: 105.174},
  {name: "73", data: 105.127},
  {name: "74", data: 105.174},
  {name: "75", data: 105.127},
  {name: "76", data: 105.198},
  {name: "77", data: 105.119},
  {name: "78", data: 105.104},
  {name: "79", data: 105.157},
  {name: "80", data: 105.161},
  {name: "81", data: 105.123},
  {name: "82", data: 105.158},
  {name: "83", data: 105.179},
  {name: "84", data: 105.111},
  {name: "85", data: 105.138},
  {name: "86", data: 105.174},
  {name: "87", data: 105.197},
  {name: "88", data: 105.179},
  {name: "89", data: 105.190},
  {name: "90", data: 105.189},
  {name: "91", data: 105.174},
  {name: "92", data: 105.132},
  {name: "93", data: 105.169},
  {name: "94", data: 105.198},
  {name: "95", data: 105.114},
  {name: "96", data: 105.122},
  {name: "97", data: 105.150},
  {name: "98", data: 105.167},
  {name: "99", data: 105.145},
  {name: "100", data: 105.198}
]