import { IAppState } from "@src/interface/IAppState";
import { createSelector } from 'reselect'
const currentOrder = (state: IAppState) => state.topState.currentOrder
const balance = (state: IAppState) => state.topState.balance
const isShownPopupWelcome = (state: IAppState) => state.topState.isShownPopupWelcome

export const currentOrderSelector = createSelector(
  currentOrder,
  order => order
)

export const balanceSelector = createSelector(
  balance,
  balance => balance
)

export const isShownPopupWelcomeSelector = createSelector(
  isShownPopupWelcome,
  isShownPopupWelcome => isShownPopupWelcome
)