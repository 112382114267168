import { orderTradeDefault } from "@src/common/constants"
import { IChartData } from "@src/interface/ITopState"

export const actionTypes = {
  UPDATE_BANLANCE: 'UPDATE_BANLANCE',
  RESET_TOP_STATE: 'RESET_TOP_STATE',
  UPDATE_CURRENT_RATE: 'UPDATE_CURRENT_RATE',
  HIDE_POPUP_CONFIRM: "HIDE_POPUP_CONFIRM",
  OPEN_TRADE: 'OPEN_TRADE',
  OPEN_TRADE_SUCCESS: 'OPEN_TRADE_SUCCESS',
  UPDATE_PROFIT_TRADE: 'UPDATE_PROFIT_TRADE',
  UPDATE_PROFIT_TRADE_SUCCESS: 'UPDATE_PROFIT_TRADE_SUCCESS',
  CLOSE_TRADE: 'CLOSE_TRADE',
  CLOSE_TRADE_SUCCESS: 'CLOSE_TRADE_SUCCESS',
  FAKE_NOW_PRICE: 'FAKE_NOW_PRICE',
  GET_TICK_LOG: 'GET_TICK_LOG',
  GET_TICK_LOG_SUCCESS: 'GET_TICK_LOG_SUCCESS',
  GET_SINGLE_NOW_PRICE: 'GET_SINGLE_NOW_PRICE',
  GET_SINGLE_NOW_PRICE_SUCCESS: 'GET_SINGLE_NOW_PRICE_SUCCESS',
  FAKE_NOW_PRICE_SUCCESS: 'FAKE_NOW_PRICE_SUCCESS',
  REMOVE_CURRENT_ORDER: 'REMOVE_CURRENT_ORDER',
  UPDATE_POPUP_WELCOME: 'UPDATE_POPUP_WELCOME'
}

export const updateBalance = (balance: number) => {
  return {
    type: actionTypes.UPDATE_BANLANCE,
    balance
  }
}

export const resetTopState = () => {
  return {
    type: actionTypes.RESET_TOP_STATE,
  }
}

export const updateCurrentRate = (current_rate: number, bid_rate: number, ask_rate: number) => {
  return {
    type: actionTypes.UPDATE_CURRENT_RATE,
    current_rate,
    bid_rate,
    ask_rate
  }
}

export const hideConfirmPopUp = () => {
  return {
    type: actionTypes.HIDE_POPUP_CONFIRM
  }
}
export const openTrade = (is_buy: boolean, start_price: number, last_price: number, cash = orderTradeDefault) => {
  return {
    type: actionTypes.OPEN_TRADE,
    is_buy,
    cash,
    start_price,
    last_price
  }
}
export const openTradeSuccess = (start_price: number, profit: number, is_buy: boolean, cash = orderTradeDefault, notional: number, last_price: number) => {
  return {
    type: actionTypes.OPEN_TRADE_SUCCESS,
    start_price,
    is_buy,
    profit,
    cash,
    notional,
    last_price
  }
}

export const updateTrade = (last_price: number) => {
  return {
    type: actionTypes.UPDATE_PROFIT_TRADE,
    last_price
  }
}

export const updateTradeSuccess = (profit: number, last_price: number) => {
  return {
    type: actionTypes.UPDATE_PROFIT_TRADE_SUCCESS,
    profit,
    last_price
  }
}

export const closeTrade = (profit: number) => {
  return {
    type: actionTypes.CLOSE_TRADE,
    profit
  }
}

export const closeTradeSuccess = (profit: number, new_balance: number) => {
  return {
    type: actionTypes.CLOSE_TRADE_SUCCESS,
    profit,
    new_balance
  }
}

export const fakeSingleNowPrice = () => ({
  type: actionTypes.FAKE_NOW_PRICE
})

export const fakeSingleNowPriceSuccess = (newData: Array<IChartData>, bidRate: number, askRate: number) => ({
  type: actionTypes.FAKE_NOW_PRICE_SUCCESS,
  newData,
  bidRate,
  askRate
})

export const getTickLog = () => ({
  type: actionTypes.GET_TICK_LOG
})

export const getTickLogSuccess = (dataLine: Array<IChartData>) => ({
  type: actionTypes.GET_TICK_LOG_SUCCESS,
  dataLine
})

export const getSingleNowPrice = () => ({
  type: actionTypes.GET_SINGLE_NOW_PRICE
})

export const getSingleNowPriceSuccess = (chartData: IChartData, bidRate: number, askRate: number) => ({
  type: actionTypes.GET_SINGLE_NOW_PRICE_SUCCESS,
  chartData,
  bidRate,
  askRate
})

export const removeCurrentOrder = () => ({
  type: actionTypes.REMOVE_CURRENT_ORDER
})

export const updatePopupWelcome = () => ({
  type: actionTypes.UPDATE_POPUP_WELCOME
})