export const actionTypes = {
  UPDATE_POPPER_ACTIVE: "UPDATE_POPPER_ACTIVE",
  UPDATE_IS_LOADING: "UPDATE_IS_LOADING",
  UPDATE_IS_SHOWED_NOTICE_MODAL: 'UPDATE_IS_SHOWED_NOTICE_MODAL'
};

export const updatePopperActive = (popper_name: string) => {
  return {
    type: actionTypes.UPDATE_POPPER_ACTIVE,
    popper_name
  };
};

export const updateIsLoading = (isLoading: boolean) => {
  return {
    type: actionTypes.UPDATE_IS_LOADING,
    isLoading
  }
}

export const updateIsShowedNoticeModal = (isShowedNoticeModal: boolean) => {
  return {
    type: actionTypes.UPDATE_IS_SHOWED_NOTICE_MODAL,
    isShowedNoticeModal
  }
}